var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.openModal)?_c('RightSideBar',{attrs:{"loading":_vm.loading,"submit":"Save","button-class":`bg-dynamicBackBtn text-white rounded`,"disabled-button":_vm.disableBtn,"close-button":_vm.disableAction},on:{"close":function($event){return _vm.closeModal()},"submit":_vm.save2FA},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{staticClass:"text-darkPurple"},[_vm._v(" Two Factor Authentication ")])]},proxy:true},{key:"subtitle",fn:function(){return [_c('div',{staticClass:"w-full mb-3"},[_c('p',{staticClass:"font-semibold text-darkPurple my-4"},[_vm._v(" Turn on two factor authentication for added protection to your account. As the root admin, you can also enforce this across your organization, thus every user has it turned on by default. ")]),_c('checkbox',{staticClass:"opacity-50",attrs:{"value":_vm.setUp ? false : true,"checkbox-size":"height:16px; width:16px; margin-top: 5px; margin-bottom: 10px;","label":"2FA disabled for all users within the organization","disabled":""},model:{value:(_vm.twoFADisabled),callback:function ($$v) {_vm.twoFADisabled=$$v},expression:"twoFADisabled"}}),_c('div',{staticClass:"mt-1 w-full flex"}),_c('checkbox',{staticClass:"opacity-50",attrs:{"value":_vm.enforceData,"checkbox-size":"height:16px; width:16px; margin-top: 5px; margin-bottom: 10px;","label":"Enforce two factor authentication for all users within this organization.","disabled":""},model:{value:(_vm.enforceData),callback:function ($$v) {_vm.enforceData=$$v},expression:"enforceData"}})],1)]},proxy:true}],null,false,216747786)},[_c('div',[_c('div',{class:[
        'flex flex-col items-start gap-3',
        _vm.visibility.first ? '' : 'opacity-50'
      ]},[_c('p',{staticClass:"text-darkPurple text-base"},[_vm._v(" Require verification code each time you login or change sensitive information in your profile. ")]),_c('p',{staticClass:"text-darkPurple text-sm"},[_vm._v(" Start by entering your password below ")]),(_vm.error.first)?_c('span',{staticClass:"text-red-500 mr-4"},[_vm._v("Incorrect Password")]):_vm._e(),_c('CText',{staticClass:"w-full",attrs:{"variant":_vm.error.first ? 'error' : '',"placeholder":"Password","disabled":!_vm.visibility.first,"type":_vm.passwordText},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('Button',{class:[' flex', _vm.visibility.first ? 'text-flame' : 'text-jet'],attrs:{"disabled":!_vm.visibility.first},on:{"click":_vm.checkPassword}},[_c('span',{staticClass:"mr-24"},[_vm._v(" Proceed ")])]),_c('checkbox',{staticClass:"flex justify-center items-center",attrs:{"label":"Show Password","checkbox-size":"width:16px; height: 16px; margin-top: 3%"},model:{value:(_vm.encryptPassword),callback:function ($$v) {_vm.encryptPassword=$$v},expression:"encryptPassword"}})],1),_c('divider',{staticClass:"my-4",attrs:{"border-dash":""}}),_c('div',{class:[
        'flex flex-col items-start gap-3',
        _vm.visibility.second ? '' : 'opacity-50'
      ]},[_c('p',{staticClass:"text-darkPurple text-base"},[_vm._v(" Enable two-factor authentication ")]),_c('RadioButton',{staticClass:"ml-2 text-base text-darkPurple",attrs:{"options":_vm.options,"col-span":"col-span-4","row-gap":"gap-x-5 ","space-between":"mr-2","disabled":!_vm.visibility.second || !_vm.$AuthUser.isRoot},model:{value:(_vm.optionValue),callback:function ($$v) {_vm.optionValue=$$v},expression:"optionValue"}}),(!_vm.$AuthUser.isRoot)?_c('small',{staticClass:"text-flame text-xs font-bold"},[_vm._v("You must be a root user to perform this action")]):_vm._e()],1),_c('divider',{staticClass:"my-4",attrs:{"border-dash":""}}),(_vm.resendLoader)?_c('div',{staticStyle:{"height":"100% mt-8"}},[_c('loader',{attrs:{"size":"xxs","loader-image":false}})],1):_c('div',[(_vm.error.second)?_c('div',{staticClass:"my-4"},[_c('span',{staticClass:"text-red-500 mb-7 flex",staticStyle:{"justify-content":"center"}},[_vm._v("Error in resending Verification code ")]),_c('Button',{class:[
            'w-full mt-4 flex',
            _vm.visibility.second ? 'text-flame' : 'text-jet'
          ],staticStyle:{"justify-content":"center"},attrs:{"disabled":!_vm.visibility.second,"width":"15rem"},on:{"click":_vm.resendCode}},[_c('span',{staticClass:"mr-4 w-full"},[_vm._v(" Resend Verification Code ")])])],1):_c('div',[(_vm.visibility.third)?_c('div',{staticClass:"flex flex-col items-start gap-3"},[_c('p',{staticClass:"text-darkPurple text-base"},[_vm._v(" Verify Email ")]),_c('p',{staticClass:"text-darkPurple text-sm"},[_vm._v(" Enter the confirmation code send to specified email address to confirm ownership. ")]),_c('v-otp-input',{ref:"otpInput",attrs:{"input-classes":"otp-input mr-2 border-jet border","separator":"","num-inputs":6,"should-auto-focus":true,"is-input-num":false},on:{"on-complete":_vm.handleOnComplete}}),_c('Button',{class:[' flex w-full', _vm.visibility.second ? 'text-flame' : 'text-jet'],attrs:{"disabled":!_vm.visibility.second},on:{"click":_vm.resendCode}},[_c('span',[_vm._v(" Resend Verification Code ")])]),_c('p',[_vm._v(" *Make sure you input the OTP correctly. Check your spam folder if you can’t find the verification email in your inbox. Otherwise, click on "),_c('span',{staticClass:"text-flame"},[_vm._v("“Resend Verification Code” ")]),_vm._v(" above to get another cornfirmation code. ")])],1):_vm._e()])])],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }