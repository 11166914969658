<template>
  <div class="py-6 w-full px-3">
    <h1 class="text-xl text-left font-bold flex mb-0 -mt-2">
      Security Policy
    </h1>
    <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    <div class="flex flex-col gap-9">
      <div class="flex justify-between items-center mt-5">
        <div class="flex flex-col">
          <p class="text-darkPurple text-l font-bold">
            My Password
          </p>
          <span class="text-sm"
            >The same password strength are enforced for all users across the
            app.</span
          >
        </div>
        <Button
          background-color="none"
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4 rounded"
          @click="passwordModal = true"
        >
          Update Password
        </Button>
      </div>
      <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    </div>
    <div class="flex flex-col gap-9">
      <div class="flex justify-between items-center mt-5">
        <div class="flex flex-col">
          <div class="flex">
            <p class="text-darkPurple text-l flex font-bold">
              Two Factor Authentication
            </p>
            <p
              class="text-xs text-mediumSeaGreen font-bold justify-end mt-1 ml-2"
            >
              {{ twoFAEnabled }}
            </p>
          </div>
          <span class="text-sm"
            >Two factor authentication enforced for all users within your
            domain.</span
          >
        </div>
        <Button
          background-color="none"
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4 rounded"
          @click="viewTwoFA"
          v-if="twoFAsetUp"
        >
          Update
        </Button>
        <Button
          background-color="none"
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4 rounded"
          @click="viewTwoFA"
          v-else
        >
          Configure
        </Button>
      </div>
      <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    </div>
    <div v-feature-request class="flex flex-col gap-9">
      <div class="flex justify-between items-center mt-5">
        <div class="flex flex-col">
          <p class="text-darkPurple text-l font-bold">
            Security Questions
          </p>
          <span class="text-sm"
            >Security questions not enforced for all users within your
            domain.</span
          >
        </div>
        <Button
          background-color="none"
          class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4 rounded"
        >
          Configure
        </Button>
      </div>
      <div class="w-full h-0 p-0 mb-4" style="border: 0.2px solid #d6dbe4" />
    </div>
    <ChangePassword
      :open-modal="passwordModal"
      @close="passwordModal = false"
    />
    <TwoFA
      :open-modal="TwoFAModal"
      @close="TwoFAModal = false"
      :status="twoFAsetUp"
      @statusInfo="setTwoFAStatus($event)"
    />
  </div>
</template>

<script>
import Button from "@/components/Button";
import ChangePassword from "./sideBar/ChangePassword";
import TwoFA from "./sideBar/2FA";

export default {
  components: {
    Button,
    ChangePassword,
    TwoFA
  },
  data() {
    return {
      passwordModal: false,
      TwoFAModal: false,
      twoFAsetUp: false,
      twoFAEnabled: ""
    };
  },
  methods: {
    setTwoFAStatus(status) {
      this.twoFAsetUp = status;
      if (status) {
        this.twoFAEnabled = "(Enabled)";
      } else {
        this.twoFAEnabled = "";
      }
    },
    get2FAStatus() {
      this.$_getTwoFactorStatus()
        .then(result => {
          this.twoFAsetUp = result.data.isSetup;
          if (this.twoFAsetUp) {
            this.twoFAEnabled = "(Enabled)";
          } else {
            this.twoFAEnabled = "";
          }
        })
        .catch(err => {
          throw new Error(err);
        });
    },
    async viewTwoFA() {
      try {
        await this.$handlePrivilege("security", "accessTwo-FactorAuthenticationSettings");
        this.TwoFAModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000
        });
      }
    }
  },
  mounted() {
    this.get2FAStatus();
  }
};
</script>

<style>
.border__dashed {
  height: 0px;
  margin: 18px 0px;
  border: 1px dashed #878e99;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
